import {
  ADD_PRODUCT,
  DELETE_PODUCT,
  EDIT_PRODUCT,
  GET_PRODUCTS,
} from "../actions/ProductActions";

const initialState = null;

const ProductReducer = (state = initialState, action) => {
  if (action.type === ADD_PRODUCT) {
    return [...state, action.payload];
  }
  if (action.type === GET_PRODUCTS) {
    return action.payload;
  }
  if (action.type === DELETE_PODUCT) {
    return state.filter((item) => item.id !== action.payload.id);
  }
  if (action.type === EDIT_PRODUCT) {
    // if(state)
    const updated = state?.map((item) => {
      if (item.id === action.payload.id) {
        return action.payload;
      } else return item;
    });
    console.log(updated);
    return updated;
  }
  return state;
};

export default ProductReducer;
